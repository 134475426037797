import React, { useState } from "react";
import "../css/Navbar.css";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  let location = useLocation();
  let [dropDownVisible, setDropDownVisible] = useState(false);

  return (
    <div
      style={
        location.pathname === "/"
          ? { position: "absolute", background: "transparent" }
          : {}
      }
      className="mainav"
      id="mainav"
    >
      <div className="menunav" id="Navbar">
        <div
          className="nav-static"
          style={location.pathname === "/" ? { borderBottom: 0 } : {}}
        >
          <div className="nav_logo">
            <Link to="/">
              <img
                src={
                  location.pathname === "/"
                    ? "/img/logo-spats-dark.jpg"
                    : "/img/logo-spats.png"
                }
                alt="logo spats"
                width={200}
              />
            </Link>
          </div>
          <a
            className="icon"
            onClick={() => {
              setDropDownVisible(!dropDownVisible);
            }}
          >
            <div
              className={`barHolder ${
                dropDownVisible ? "responsive-bars" : ""
              }`}
            >
              <span className="bar bar1"></span>
              <span className="bar bar2"></span>
              <span className="bar bar3"></span>
            </div>
          </a>
        </div>
        <ul
          className={`nav-responsive ${
            dropDownVisible ? "drop-down-nav" : ""
          } `}
          onClick={() => {
            setDropDownVisible(false);
          }}
        >
          <li className={location.pathname === "/about" ? "active-nav" : ""}>
            <Link to="/about">About</Link>
          </li>
          <li className={location.pathname === "/events" ? "active-nav" : ""}>
            <Link to="/events">Events</Link>
          </li>
          <li>
            <a href="https://nssc.in/" target="_blank">
              NSSC
            </a>
          </li>
          <li className={location.pathname === "/team" ? "active-nav" : ""}>
            <Link to="/team">Team</Link>
          </li>
          <li className={location.pathname === "/latest" ? "active-nav" : ""}>
            <Link to="/latest">Latest Updates</Link>
          </li>
          <li className={location.pathname === "/blog" ? "active-nav" : ""}>
            <Link to="/blog">Blog</Link>
          </li>
          <li className={location.pathname === "/contact" ? "active-nav" : ""}>
            <Link to="/contact">Contact</Link>
          </li>
          {/* <li className={location.pathname === "/publication" ? "active-nav" : ""}>
            <Link to="/publication">Publications</Link>
          </li> */}
          <li className={location.pathname === "/stac" ? "active-nav" : ""}>
            <Link to="/stac">STAC</Link>
          </li>
        </ul>
      </div>
      {/* <button onClick={refresh}>refresh</button> */}
    </div>
  );
};

/* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
var prevScrollpos = window.scrollY;
window.onscroll = function () {
  var currentScrollPos = window.scrollY;
  // to check if the navbar is on landing page or on other pages
  if (
    document.getElementById("mainav").className === "mainav" &&
    document.getElementById("mainav").style.position !== "absolute"
  ) {
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("mainav").style.top = "0";
    } else {
      document.getElementById("mainav").style.top = "-7rem";
    }
  }
  prevScrollpos = currentScrollPos;
};

export default Navbar;
